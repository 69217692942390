import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeDa from '@angular/common/locales/da';
import localeDeCh from '@angular/common/locales/de-CH';
import localeEn from '@angular/common/locales/en';
import localeEt from '@angular/common/locales/et';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeFrCh from '@angular/common/locales/fr-CH';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeLt from '@angular/common/locales/lt';
import localeLv from '@angular/common/locales/lv';
import localeNo from '@angular/common/locales/nb';
import localeNl from '@angular/common/locales/nl';
import localeNlBe from '@angular/common/locales/nl-BE';
import localePl from '@angular/common/locales/pl';
import localeRo from '@angular/common/locales/ro';
import localeSv from '@angular/common/locales/sv';
import { APP_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ConfigModule, ErrorHandlingModule } from '@spartacus/core';
import { DefaultStorefrontModule } from '../../../storefrontlib/src/default-storefront.module';
import { SuperStorefrontComponent } from '../../../storefrontlib/src/layout/main/super-storefront.component';
import { environment } from '../environments/environment';

registerLocaleData(localeCs);
registerLocaleData(localeDa);
registerLocaleData(localeDeCh);
registerLocaleData(localeEn);
registerLocaleData(localeEt);
registerLocaleData(localeFi);
registerLocaleData(localeFrBe);
registerLocaleData(localeFrCh);
registerLocaleData(localeHu);
registerLocaleData(localeIt);
registerLocaleData(localeLt);
registerLocaleData(localeLv);
registerLocaleData(localeNl);
registerLocaleData(localeNlBe);
registerLocaleData(localeNo, 'no');
registerLocaleData(localePl);
registerLocaleData(localeRo);
registerLocaleData(localeSv);
registerLocaleData(localeFr);

const devImports: ModuleWithProviders<any>[] = [];

if (!environment.production) {
  devImports.push(StoreDevtoolsModule.instrument({ connectInZone: true }));
}

@NgModule({
  imports: [HttpClientModule, DefaultStorefrontModule, ErrorHandlingModule.forRoot(), ...devImports, ConfigModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }, { provide: APP_ID, useValue: 'kasbah' }, provideClientHydration()],
  bootstrap: [SuperStorefrontComponent],
})
export class AppModule {}
