import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { GlobalMessageService as CxGlobalMessageService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { Translatable } from '../../i18n/translatable';
import { GlobalMessageType } from '../models/global-message.model';
import { StateWithGlobalMessage } from '../store/global-message-state';
import { GlobalMessageSelectors } from '../store/selectors/index';

@Injectable({
  providedIn: 'root',
})
export class GlobalMessageService extends CxGlobalMessageService {
  constructor(protected store: Store<StateWithGlobalMessage>) {
    super(store);
  }

  add(text: string | Translatable, type: GlobalMessageType, timeout?: number): void {
    super.add(this.replaceCustomTranslationKeys(text), type, timeout);
  }

  /**
   * Get one message by uid
   * @param messageType: GlobalMessageType object
   * @param uid: string
   */
  getGlobalMessageByUid(messageType: GlobalMessageType, uid: string): Observable<Translatable> {
    return this.store.pipe(select(GlobalMessageSelectors.getGlobalMessageEntityByUid(messageType, uid)));
  }

  // NOTE: This could be removed if we migrate translation keys, see ESVCX-7762
  private replaceCustomTranslationKeys(text: string | Translatable): string | Translatable {
    if (typeof text === 'string') {
      return text;
    }
    const customTranslationKeysMap = {
      'asm.auth.agentLoggedInError': 'asm.agentLoginError_message',
      'asm.csagentTokenExpired': 'asm.sessionExpired_message',
      'httpHandlers.badGateway': 'errors.badGateway_message',
      'httpHandlers.badRequest.bad_credentials': 'errors.invalidCredentials_message',
      'httpHandlers.badRequest.your_account_is_disabled,_please_contact_customer_support.': 'errors.accountDisabled_message',
      'httpHandlers.conflict': 'errors.conflict_message',
      'httpHandlers.forbidden': 'errors.forbidden_message',
      'httpHandlers.gatewayTimeout': 'errors.gatewayTimeout_message',
      'httpHandlers.internalServerError': 'errors.internalServerError_message',
      'httpHandlers.sessionExpired': 'errors.sessionExpired_message',
    };
    if (text.key in customTranslationKeysMap) {
      return { ...text, key: customTranslationKeysMap[text.key] };
    }
    return text;
  }
}
