import { OccConfig } from '@spartacus/core';

export const defaultOccSiteContextConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        languages: 'languages',
        currencies: 'currencies',
        countries: 'countriesByType',
        baseSites:
          'basesites?fields=baseSites(uid,defaultLanguage(isocode),urlEncodingAttributes,urlPatterns,stores(currencies(isocode),defaultCurrency(isocode),languages(isocode,nativeName),defaultLanguage(isocode),countryCode),theme,defaultPreviewCatalogId,defaultPreviewCategoryCode,defaultPreviewProductCode,sitePrefix,baseUrl,dateFormat,dateTimeFormat,cdnBaseUrl)'.replace(
            /,/g,
            '%2C'
          ),
      },
    },
  },
};
