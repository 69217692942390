import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { UserIdService } from '../../auth/user-auth/facade/user-id.service';
import { Country } from '../../model/address.model';
import { SiteContextActions } from '../store/actions/index';
import { SiteContextSelectors } from '../store/selectors/index';
import { StateWithSiteContext } from '../store/site-context-state';

@Injectable({ providedIn: 'root' })
export class SiteCountryService {
  constructor(protected store: Store<StateWithSiteContext>, protected userIdService: UserIdService) {}

  loadCountry(countryCode: string): void {
    this.userIdService
      .getUserId()
      .pipe(take(1))
      .subscribe((userId) => this.store.dispatch(new SiteContextActions.LoadSiteCountry(userId, countryCode)));
  }

  getActiveCountry(): Observable<Country> {
    return this.store.pipe(
      select(SiteContextSelectors.getActiveCountry),
      filter((active) => Boolean(active))
    );
  }
}
