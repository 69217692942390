import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import { AuthService, EventService, LoggerService, RoutingService } from '@spartacus/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { NzIconService } from 'ng-zorro-antd/icon';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { GlobalMessageService, GlobalMessageType } from '../../core/global-message';
import { RoutingHelperService } from '../../core/routing';
import { BaseSiteService } from '../../core/site-context';
import { ExecuteRecaptchaEvent, ExperimentalFeaturesFacade, RecaptchaCompleteEvent } from '../../core/user';
import { HeaderResizeService } from '../../shared/services/header-resize/header-resize.service';
import { BRAND_ICONS, USED_LIBRARY_ICONS } from './icons.constants';

@Component({
  selector: 'py-storefront',
  templateUrl: './storefront.component.html',
  styleUrls: ['./storefront.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorefrontComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('currentCustomerBannerElement') currentCustomerBannerElement: ElementRef<HTMLElement>;
  @HostBinding('class.zoomed-out') isZoomedOut = false;
  @ViewChild('captchaRef') captchaRef: RecaptchaComponent;

  private subscriptions = new Subscription();

  isUserLoggedIn$: Observable<boolean>;
  hasDebugMenu$: Observable<boolean>;
  searchBoxVisible$: Observable<boolean>;
  modalClosed$ = new Subject<string>();

  protected logger = inject(LoggerService);

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private routingService: RoutingService,
    private routingHelperService: RoutingHelperService,
    private authService: AuthService,
    private baseSiteService: BaseSiteService,
    private globalMessageService: GlobalMessageService,
    private nzIconService: NzIconService,
    protected headerResizeService: HeaderResizeService,
    private experimentalFeaturesService: ExperimentalFeaturesFacade,
    private eventService: EventService,
    @Inject(PLATFORM_ID) protected platform: any,
    @Optional() @Inject(BRAND_ICONS) protected brandIcons: IconDefinition[]
  ) {}

  @HostListener('window:resize')
  onResize() {
    this.setIsZoomedOut();
  }

  reCaptchaComplete(recaptchaKey: string) {
    if (recaptchaKey) {
      // eslint-disable-next-line no-console
      this.logger.info('reCaptcha complete', recaptchaKey);
      this.eventService.dispatch(new RecaptchaCompleteEvent(recaptchaKey));
    }
  }

  reCaptchaError(error) {
    // eslint-disable-next-line no-console
    this.logger.info('reCaptcha error', error);
  }

  initReCaptcha() {
    this.eventService.get(ExecuteRecaptchaEvent).subscribe(() => {
      // eslint-disable-next-line no-console
      this.logger.info('waiting for reCaptcha', this.captchaRef);
      this.captchaRef.reset();
      this.captchaRef.execute();
    });
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.initReCaptcha();
      this.handleDisplayingOnFallbackBaseSite();
      this.subscriptions.add(
        this.isUserLoggedIn$.pipe(filter(Boolean), take(1)).subscribe(() => {
          this.headerResizeService.observe(this.currentCustomerBannerElement?.nativeElement);
        })
      );
      this.subscriptions.add(
        this.routingService
          .isNavigating()
          .pipe(distinctUntilChanged())
          .subscribe((isNavigating) => {
            if (isNavigating) {
              this.renderer.addClass(this.elementRef.nativeElement, 'navigating');
            } else {
              this.renderer.removeClass(this.elementRef.nativeElement, 'navigating');
            }
          })
      );
    }
  }

  setIsZoomedOut(): void {
    if (isPlatformBrowser(this.platform)) {
      if (window.devicePixelRatio < 1) {
        this.isZoomedOut = true;
      } else {
        this.isZoomedOut = false;
      }
    }
  }

  loadIconsStaticallyForSSR(): void {
    if (!isPlatformBrowser(this.platform)) {
      // Loading library icons statically to fix the issue with icons not being rendered in SSR
      this.nzIconService.addIcon(...USED_LIBRARY_ICONS);
      if (this.brandIcons) {
        this.nzIconService.addIcon(...this.brandIcons);
      }
    }
  }

  ngOnInit() {
    this.loadIconsStaticallyForSSR();

    if (isPlatformBrowser(this.platform)) {
      //If we put setIsZoomedOut in the ngAfterViewInit method we get a ExpressionChangedAfterItHasBeenCheckedError
      this.setIsZoomedOut();

      this.searchBoxVisible$ = this.routingHelperService.searchBoxVisible();
      this.isUserLoggedIn$ = this.authService.isUserLoggedIn();
      this.hasDebugMenu$ = this.experimentalFeaturesService.hasDebugMenu();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.headerResizeService.unobserve(this.currentCustomerBannerElement?.nativeElement);
  }

  private handleDisplayingOnFallbackBaseSite() {
    if (this.baseSiteService.isFallback()) {
      this.globalMessageService.add(
        { key: 'errors.pageNotFoundRedirectedToLandingPage_message' },
        GlobalMessageType.MSG_TYPE_INFO
      );
      this.baseSiteService.setIsFallback(false);
    }
  }
}
