import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CategoryRef } from '../../model';

@Injectable({
  providedIn: 'root',
})
export class CatalogRouterStateService {
  constructor() {}

  getProductCode(params?: Params, queryParams?: Params): string {
    return this.getCode('--p', params, queryParams?.p);
  }

  getLeafCategoryCode(params?: Params, queryParams?: Params): CategoryRef {
    return this.getCode('--c', params, queryParams?.c) as CategoryRef;
  }

  private getCode(key: string, params?: Params, defaultValue?: string): string {
    if (!params && !defaultValue) {
      return undefined;
    }

    const leafParam = Object.values(params || {})
      .filter((param) => param.includes(key))
      .pop();

    const code = leafParam?.split(key)?.pop();

    return code || defaultValue;
  }
}
