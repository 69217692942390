import { Inject, Injectable, isDevMode, OnDestroy } from '@angular/core';
import { I18nextTranslationService as CxI18NextTranslationService, I18nConfig } from '@spartacus/core';
import { i18n, TFunction } from 'i18next';
import { Observable, of, Subscription } from 'rxjs';
import { DebugKey } from '../../model';
import { DebugFacade } from '../../user';
import { TranslationChunkService } from '../services/translation-chunk.service';
import { TranslationService } from '../services/translation.service';
import { I18NEXT_INSTANCE } from './i18next-instance';

@Injectable({ providedIn: 'root' })
export class I18nextTranslationService extends CxI18NextTranslationService implements TranslationService, OnDestroy {
  private debug: boolean = false;
  private subscriptions = new Subscription();

  constructor(
    protected config: I18nConfig,
    protected translationChunk: TranslationChunkService,
    protected debugService: DebugFacade,
    @Inject(I18NEXT_INSTANCE) protected i18next: i18n
  ) {
    const t = i18next.t;
    i18next.t = ((namespacedKey: string, options: any = {}): string => {
      if (this.debug) {
        return namespacedKey.split(this.NAMESPACE_SEPARATOR).slice(-1)[0];
      }
      const translated = t(namespacedKey, options);
      // '.' means empty, '' would not work as it would fall back to English
      const translationResult = translated as unknown as string;
      return translationResult === '.' ? '' : translationResult;
    }) as TFunction<'translation'>;

    super(config, translationChunk, i18next);

    this.listenToDebugState();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  translate2(key: string, _options: any = {}, _whitespaceUntilLoaded: boolean = false): Observable<string> {
    const chunkName = this.translationChunk.getChunkNameForKey(key);
    if (isDevMode()) {
      this.logger.warn(`Translation key missing '${key}' in the chunk '${chunkName}'`);
    }
    return of(key);
  }

  private listenToDebugState(): void {
    this.subscriptions.add(
      this.debugService.isDebugFeatureEnabled(DebugKey.TRANSLATION_KEYS).subscribe((value) => {
        if (value !== this.debug) {
          this.debug = value;
          // Trigger an update of translated strings
          this.i18next.changeLanguage(this.i18next.language);
        }
      })
    );
  }
}
